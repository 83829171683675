body {
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
}


.box {
  width: 300px;
  h1 {
    font-size: 20px;
    margin: 0 0 1rem 0;
  }
}


#uiFeatures #controls {
  top: auto;
  left: auto;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .loadingmargin{
    margin-top:260%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .loadingmargin{
    margin-top:440%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .loadingmargin{
    margin-top:440%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .loadingmargin{
    margin-top:140%;
  }
}